@import "./../../scss/variables.scss";

.logo{
  position: absolute;
  left: 25px;
  top:25px;
  width:192px;
  height:81px;

  @include breakpoint("l") {
    left: 50px;
    top:50px;
  }
  background: url('../images/logo.png') center center / contain
}