@import "./../../scss/variables.scss";
.button {
  user-select: none;
  border-radius: 99999px;
  display: inline-block;
  border: 4px solid $blue-lighter;
  position: relative;
  color: $white;

  width: 125px;
  height: 125px;
  @include breakpoint("l") {
    width: 200px;
    height: 200px;
  }

  background: center center/cover no-repeat;
  cursor: pointer;
  z-index: 1;
  transition: border-color 0.25s;
  .type {
    display: none;
  }
  &.scan {
    position: relative;
    .type {
      position: absolute;
      display: block;
      width: 50%;
      height: 50%;
      background: linear-gradient(
        45deg,
        rgba(0, 0, 0, 0) 50%,
        $petrol-lighter 100%
      );
      border-radius: 100% 0 0 0;
      top: 0;
      left: 0;
      animation: scan 5s linear infinite;
      transform-origin: 100% 100%;
    }
  }
  .label {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:16px;
    text-transform: uppercase;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    @include breakpoint("l") {
      font-size: 22px;

    }
  }
  &::before {
    border-radius: 99999px;
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent linear-gradient(0deg, $basic-gradient);
    z-index: -1;
    transition: opacity 0.25s;
    opacity: 0;
  }
  &::after {
    border-radius: 99999px;
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent linear-gradient(90deg, $basic-gradient);
    z-index: -1;
    transition: opacity 0.25s;
    opacity: 1;
  }

  &:hover,
  &.active {
    border-color: $blue-light;
    &.active {
      border-color: $white;
    }
    &::before {
      opacity: 1;
    }
    &::after {
      opacity: 0;
    }
  }

  &:active {
    border-color: $white;
  }

  .badge {
    position: absolute;
    bottom: 0;
    left: 10%;
    right: 0;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    font-size: 10px;
    @include breakpoint("l") {
      font-size: 22px;

    }
    & > div {
      border: 2px solid $functional-red;
      background: transparent linear-gradient(90deg, $functional-red-gradient);
      border-radius: 4px;
      padding: 2px 6px;
      transform-origin: left bottom;
      transform: rotateZ(-15deg);
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.25;
  }
}
@keyframes scan {
  to {
    transform: rotate(1turn);
  }
}
