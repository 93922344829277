@import "./../../scss/variables.scss";

.Point {
  width: 75px;
  height: 75px;
  position: relative;
  transform: scale(.6);
  cursor: pointer;

  & >  div{
    width: 75px;
    height: 75px;
    position: relative;
    opacity: 0;
    transform: scale(0);
 
  }
  svg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .spinner {
    // transform: rotate(-90deg);
    border-radius: 50%;
    // animation: rotate 2s linear infinite, opac 2s linear infinite;
    .spinner__g {
      opacity: 0.5;
    }
    .spinner__ani {
      fill: transparent;
      stroke: $natural-red;
      stroke-width: 50;

      stroke-dasharray: 0 158; /* 2π × 25 ≈ 158 */
      //  animation: fillup 2s linear infinite;
    }
  }

  transition: transform 0.25s;
  .spinner,
  .spinner__ani {
    transition: transform 0.25s;
  }
  &:hover {
    transform: scale(1);

    .spinner,
    .spinner__ani {
      //animation-play-state: paused;
      opacity: 0;
    }
  }
  .inner {
    transform: scale(0.4);
    .exlamation {
      opacity: 0;
    }
  }

  .fill__red {
    fill: $natural-red;
  }

  .bg {
    opacity: 0.3;
  }

  @keyframes fillup {
    0% {
      stroke-dasharray: 0 158;
    }
    50%,
    100% {
      stroke-dasharray: 158 158;
    }
  }
  @keyframes rotate {
    0% {
      transform: rotate(-90deg);
    }
    50%,
    100% {
      transform: rotate(270deg);
    }
  }
  @keyframes opac {
    0% {
      opacity: 0;
    }
    25%,
    75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
