@import "./../../scss/variables.scss";

.tooltips {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .tooltip-box {
    position: absolute;
    right: 30%;
    top: 50%;
    max-width: 200px;

    color: $white;
    font-weight: bold;
    text-transform: uppercase;
    & > div {
      background: $natural-red;
      padding: 10px;
    }
    & > ul{
      padding: 10px;
      list-style-type:disc;
      background: $blue;
      margin-top: 5px;
      li{
        margin-left: 15px;
      }
    }
  }
  .tooltips-inner {
    position: relative;
    width: 100vw;
    height: 100vh;
    margin-top: -20%;
    .tooltips-scale {
      width: 100%;
      height: 100%;
      position: relative;
    }
    .tooltip {
      position: absolute;
      transform-origin: 50% 50%;
      margin-left: -40px;
      margin-top: -35px;
      pointer-events: none;
      .tooltip-inner {
        box-sizing: border-box;
        width: 40px;
        height: 40px;
        border: 4px solid $natural-red;
        background-color: rgba($natural-red, 0.5);
        border-radius: 50%;
        transition: transform 0.25s, background-color 0.25s;
      }
      .inner {
        pointer-events: all;
        cursor: pointer;
      }
      &:hover {
        .tooltip-inner {
          transform: scale(1.15);
          background-color: $natural-red;
          cursor: pointer;
        }
      }
    }
  }
}
