@import "./../../scss/variables.scss";

.StepNavigation {
  position: absolute;

  width: 220px;
  left: 25px;
  top: 145px;

  @include breakpoint("l") {
    width: 300px;
    left: 50px;
    top: 170px;
  }
}
.StepNavigationButton {
  display: block;
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 10px;
  background: $petrol-gradient-background;
  opacity: 0.5;

  &:hover,
  &.active {
    opacity: 1;
  }

  &.pulse {
    animation: pulse 0.5s alternate infinite;
  }
  text-transform: uppercase;
  font-size: 18px;
  color: $white;
  i {
    color: $blue;
    font-size: 36px;
    display: block;
  }
  span {
    font-size: 14px;
    display: block;
    text-transform: none;
    color: $blue;
  }

  @include breakpoint("l") {
    font-size: 24px;
    i {
      font-size: 50px;
    }
    span {
      font-size: 18px;
    }
  }
}
@keyframes pulse {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}
