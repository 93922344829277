.button-bar {
  position: absolute;
  left: 0;
  bottom: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & > * {
    margin: 0 20px;
  }
}
