@charset "UTF-8";
@import "./reset";


// Import Siemens CSS

@import "./fonts.scss";

.main{
  


}

body{
  overflow: hidden;
}