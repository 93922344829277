.Intro {
  position: absolute;
  left: 0;
  right: 0;
  top: 0%;
  bottom: 10%;
  display: flex;
  align-items: center;
  justify-content: center;

  .inner {
    position: absolute;
    left: 0;
    right: 0;
    top: 5%;
    bottom: 30%;
  }
  canvas,
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  

  .bubble-outer {
    position: absolute;
    bottom: 0;
  }
  img, .bubble-outer, .intro-video {
    opacity: 0;
    transition: 0.25s;
    &.show {
      opacity: 1;
    }
  }

  .squaredButtons{
    bottom: 0;
  }
}
