.usecase {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .plant {
    margin: auto;
  }
  .bubble-align {
    position: absolute;
    top: 10%;
    left: 0;
    right: 0;
  }
  .Plant {
    width: 100vw;

    height: 100vh;
    margin-top: -20%;
    position: relative;
    img,
    video {
      position: absolute;

      //display: block !important;
      &.ratioHelper {
        opacity: 0;
        object-fit: initial;
      }
      &.show {
        opacity: 1;
      }
      &.hide {
        opacity: 0;
      }
      //object-position: 50% 100%;
    }
    video {
      display: none;
    }
    canvas {
      position: absolute;
      &.show {
        opacity: 1;
      }
      &.hide {
        opacity: 0;
      }
    }

    .centered {
      justify-content: center;
      align-items: center;
      display: flex;
      height: 100%;
      width: 100%;
      position: relative;
    }
  }
}
