@import "./variables.scss";


@font-face {
  font-family: "Siemens Sans";
  src: url("/fonts/SiemensSans_Prof_Roman.eot");
  src: url("/fonts/SiemensSans_Prof_Roman.eot?#iefix") format("embedded-opentype"),
    url("/fonts/SiemensSans_Prof_Roman.woff") format("woff"),
    url("/fonts/SiemensSans_Prof_Roman.woff2") format("woff2"),
    url("/fonts/SiemensSans_Prof_Roman.ttf") format("truetype");
}
@font-face {
  font-family: "Siemens Sans ";
  src: url("/fonts/SiemensSans_Prof_Bold.eot");
  src: url("/fonts/SiemensSans_Prof_Bold.eot?#iefix") format("embedded-opentype"),
    url("/fonts/SiemensSans_Prof_Bold.woff") format("woff"),
    url("/fonts/SiemensSans_Prof_Bold.woff2") format("woff2"),
    url("/fonts/SiemensSans_Prof_Bold.ttf") format("truetype");
  font-weight: 700;
}

body {
  font-weight: normal;
  font-family: "Siemens Sans", Arial, sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6,
.strong {
  font-weight: 700;
}

h1{
font-size:22px;
font-weight: 400;
@include breakpoint("l") {
  font-size:36px;

}
}
