@import "./../../scss/variables.scss";
@import "./../../scss/fonts.scss";

.bubble-align {
  display: flex;
  align-items: center;
  flex-direction: column;
  pointer-events: none;
}
.bubble {
  pointer-events: none;
  color: $white;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;

  max-width: 50vw;
  .bubble-inner {
    background: transparent linear-gradient(90deg, $basic-gradient);
    text-align: center;
    padding: 25px;
    @extend h1;
  }
  .avatar {
    display: block;
    width: 100px;
    height: 100px;
    background: center center/cover no-repeat;
    border-radius: 9999px;
    margin: 20px 0;
  }

  &:after {
    content: "";
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 25px 25px 0 25px;
    border-color: rgba(#005f87, 0.6) transparent transparent transparent;
    left: calc(50% - 25px); 
    bottom:-25px;       position: absolute;

  }

  &[data-arrow="top"] {

    &:after {
      border-width: 0 25px 25px 25px;
      top: -25px;
      border-color: transparent transparent rgba(#005f87, 0.6) transparent;
    }
  }
}
