@import "./../../scss/variables.scss";
.squaredButtons {
  position: absolute;
  bottom: 50px;
  right: 50px;
}
.squaredButton {
  margin-left: 20px;
  color: $blue-lighter;
  text-decoration: none;
  &.labeled {
    border: 4px solid $blue-lighter;
    padding: 10px 20px;
    height: 30px;
  }
  transition: fill 0.25s,color 0.25s, border-color 0.25s;
  cursor: pointer;
  label{
    cursor: pointer;

  }
  svg {
    padding: 10px;

    width: 30px;
    height: 30px;
    fill: $blue-lighter;
    border: 4px solid $blue-lighter;
  }
  &:hover {
    fill: $white;
    color: $white;
    border-color: $white;
    svg {
      fill: $white;
      border-color: $white;
    }
  }
}
