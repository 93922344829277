.MobileInfo {
  z-index: 99;
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(#ebf0f5, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .useDesktop,
  .rotateDevice {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .icon {
      width: 20vw;
      height: 20vw;
      background: url("../images/icon-desktop.svg") no-repeat center center;
      background-size: contain;
      margin-bottom: 2vh;
    }

    .infoText {
      max-width: 75vw;
      text-align: center;
    }
  }

  .rotateDevice {
    display: none;
    @media (min-width: 768px) and (orientation: portrait) {
      display: flex;
    }
    .icon {
      background-image: url("../images/icon-tablet-rotate.svg");
    }
  }

  @media (min-width: 768px) and (min-height: 400px) {
    .useDesktop {
      display: none;
    }
  }

  @media (min-width: 768px) and (min-height: 400px) and (orientation: landscape) {
    display: none;
  }
}
