@import "./../../scss/variables.scss";


.OverlayMedia {
  position: absolute;
  right: 25px;
  top: 45px;
  video{
    width: 180px;
    height: 576px;
  }
  @include breakpoint("l") {
    right: 50px;
    top: 70px;
    video{
      width:260px;
      height: 832px;
    }
  }

  canvas {
    &.show {
      opacity: 1;
    }
    &.hide {
      opacity: 0;
    }
  }
  
}


