$media-direction: min;
$breakpoints: (
  "default": "",
  "m": 1024px,
  "l": 1280px,
  "xl": 1680px,
);
@import './mixins.scss';

$white: #fff;
$black: #000;

$petrol: #009999;
$petrol-lighter: #42c1c5;
$petrol-light: #00aaae;
$petrol-normal: #00a8c0;
$petrol-dark: #00a8c0;

$blue-lighter: #50bed7;
$blue-light:#2387AA;
$blue:#005578;

$yellow: #faa50a;
$yellow-dark:#eb780a;
$sand: #00000080;

$stone-lighter: #ebf0f5;
$stone-light: #697882;
$stone: #788791;
$stone-dark: #2d373c;

$gray-light: #cdd9e1;
$gray: #879baa;
$functional-red:#900;
$natural-red:#af235f;

$petrol-gradient:  rgba(#42c1c5, 0.95) 0%,
rgba(#00aaae, 0.95) 58%,
rgba(#00a8c0, 0.95) 79%,
rgba(#00a6da, 0.95) 100%;

$basic-gradient:  rgba(#005f87, 0.95) 0%,
rgba(#005f87, 0.25) 100%;

$functional-red-gradient:  rgba($functional-red, 0.95) 0%,
rgba($functional-red, 0.75) 100%;




$petrol-gradient-background:  transparent
linear-gradient(
  90deg,
  $petrol-gradient
)
0% 0% no-repeat padding-box;


.lds-ring {
  display: inline-block;
  position: absolute;
  left: 50%;
  top:50%;
  width: 80px;
  height: 80px;
  margin-left: -40px;
  margin-top: -40px;
  z-index: 99;
  transition:  opacity .25s .25s;
  &.hidden{
    opacity: 0;
  }
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}